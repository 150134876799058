@import "github.css";

*{box-sizing: border-box;}
	body{
		font-size:13px; font-family: "tenso", sans-serif;
	letter-spacing:.05em;
	margin:0; padding:0;

	// overflow:hidden;
	background:#FAF2E5;

}
.top2{
	margin-bottom: 7vh;
letter-spacing: .1em;
text-transform: uppercase;
}
.note{
	padding: 10px;
	background: rgba(255, 210, 210, 0.5);
	display: inline-block;
	margin-bottom: 20px;
}
table{
	width: 100%;
	th, td{
		text-align: left;
	}
}
.super{
	font-size: 12px !important;
	margin-bottom: 0;
	code{
		font-size: 12px !important;
	}
}
.table-section{
	border-top:1px solid #000;
	border-bottom:1px solid #000;
	padding: 15px 0;
	margin: 20px 0;
}
pre{
	&.highlight{
		padding:15px 30px;
		margin: 40px 0;
		overflow-x: scroll;

	}
}
	body.home{
		overflow: hidden;
		height:100vh;
	}
	body.organized{
		min-height:100vh;
		overflow:auto !important;
	}

	body.pre li{cursor:default;}
  .blog-link{
    background: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    padding: 4px 8px;
    &:hover{
      background: white;
    }
  }
  .blog{
		.group{
			margin-bottom:15vh;
			margin-top: 12vh;
		}
		.post{
			img{
				max-width:100%;
				box-shadow: 3px 3px 14px rgba(0,0,0,.1);
    		border: 5px solid white
			}
			blockquote{
				border-left: 7px solid white;
		    padding: 0 30px;
		    margin: 30px 0;
		    font-style: italic;
			}
			p{
				font-size:14px;
			}
			a{
				text-decoration: underline;
			}
		}

		h1{
			font-weight:500;
			font-size:24px;
			text-transform: none;
		}
		h3{
			font-weight:500;
			font-size:16px;
			margin-top:7vh;
		}
		.highlight{
			background: #fdfbf6;
			border-radius: 15px;
			-moz-tab-size: 2;
			-o-tab-size: 2;
			tab-size: 2;
		}
		code{
			background: #fdfbf6;
			border-radius: 3px;
			padding:4px 8px;
			display:inline-block;
			font-size: 12px;
		}
    #blog-about{
      position: fixed;
      width: auto;
      top: 5vh;
      padding: 4vh 4vw;
      border-right: 1px solid black;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      border-color: #b3b3b3;
      .button{
        border:1px solid #ccc;
        background: rgba(0,0,0,.05);
        border-radius: 3px;
        padding:15px 30px;
      }
    }

    main{
      max-width:600px;
      line-height:1.8;
      margin-left: 35vw;
      .top{
        margin-top:8vh;
      }
			.post{
				margin-bottom:20vh;
			}
    }
    .sb2{

      margin-top: 20px;

      font-size: 13px;
      // letter-spacing: .05em;
      a{
        // border-bottom: 1px solid #b3b3b3;
        padding-bottom: 3px;
        display:inline-block;
				&:hover{
					color: blue;
				}
      }
			.date{
				color: #aaa;
				margin-left: 35px;
				width: 80px;
				display: inline-block;
			}
    }
    .sb3{
      a{
        display: inline-block;
        padding-bottom: 1px;
        border-bottom: 1px solid rgba(0,0,0,0);
        &:hover{
          border-color:#ccc;
        }
      }
    }
  }

	.vis{display:none; position:relative; left:16px; font-style: italic;}

	#mine img, #mobileinfo img{width:16px;}
	#mine a, #mine span{margin-right:4px; display:inline-block; margin-left:3px;}
	#mine p:nth-of-type(2){margin-bottom:10px;}
	#mine p a img{}
	#mine p a img:hover{filter:drop-shadow(2px 2px 5px #4444dd)}
	#about{position: fixed;
		width:auto; top:5vh;


		padding:4vh 4vw;
		border-right:1px solid black;
		border-top:1px solid black;
		border-bottom:1px solid black;
		opacity:0;
		transition:.5s ease;
		box-shadow:0px 3px 15px rgba(0,0,0,0.2);
		border-color:#b3b3b3;
		left:-5vw;

	}



	.organized #about{left:0;}
	#about p{margin:1vh 0;}
	#about #options p{margin:2vh 0;}

	#about > div:not(:first-child){margin-top: 6vh}


	#about a, #about span, #about p{}

	#index{
		position: relative;
	    top: 5vh;
	    margin-left:30vw;
	    max-width:69vw;
	    padding-bottom:10vh;
	}


	#yours p:not(:first-child)::before{content:"\262F\2002";
		font-size:.8em;
	}
	#mine{
		.art{
			p{
				margin:1vh 0;
			}
			p::before{
				content:"\1604\2002";
				// font-size:.8em;
			}
		}
	}

	#index .top{margin-top:4vh;}

	.sb{
		border-bottom: 1px solid #b3b3b3;
	    display: inline-block;
	    padding-bottom: 3px;
	    margin-top:0;
	    text-transform: uppercase;
    	font-size: 11px;
    	letter-spacing: .05em;
		}




	.hide{opacity:0; transition: .5s ease;}
	.organized .hide{opacity:1;}



	.group{margin-top:18vh;}

  #index{
    .group:first-of-type{
      margin-top:14vh;
    }
  }

	.projects{opacity:0; transition:.3s ease; transition-property: opacity ;
		position:relative; margin: 5vh 0; }
	.projects.partialin{opacity:.5;}



	.projects:first-child{margin-top:10px;}
	.projects .dots{ transition:.3s; padding-left:1vw; }
	.projects .tlink{display:inline-block;
		background:rgba(255,255,255,.85);
		padding:6px 8px;
		transition:	.2s ease;

		border: 1px solid #FEFDFB;
    	border-radius: 3px;
	}
	.projects a:hover .tlink{background:rgba(255,255,255,1); border-color:blue; }
	.projects a:hover .highlightp{border-color:blue;}


	.details{ display:inline-block;
		 transition:.5s ease; transition-property: opacity;
		float:left; padding-left:1vw;
		position:relative;
		top:7px;
	}
	.projects a{display:block; margin:2vh 0; }
	.projects { width: 65vw; }
	.projects .title { float: left; width: 20vw;
		overflow: hidden; white-space: nowrap;

	}
	.details { float: left; width: 45vw; overflow: hidden; padding-bottom:3px;}
	.projects .title span.dots:after { content: " .................................................................................." }




	.organized #about, .organized #index, .organized #filter{opacity:1;}

	.organized .projects{
		bottom:0 !important; left:0 !important;
	}


	.top{margin-bottom:7vh; letter-spacing:.1em; text-transform: uppercase;}

	.p1, .p2{opacity:0; transition:.5s ease;}
	.p1.in, .p2.in{opacity:1;}


	// #mine a{position: relative;}
	#mine a:hover{color:blue;}


	#options a{cursor:pointer; background:rgba(0,0,0,.05); border-radius:2px; padding:4px 8px;}
	#options a:hover{background:white;}

	#options p:nth-of-type(2) a{}



	#moreinfo{
		position:fixed;
    top: calc(50vh - 130px);
    left: calc(50vw - 255px);

		background:rgba(255,255,255,.97);
		border-radius:2px;
		display:none;
		padding:5vh 5vw;
		max-width:70vw;
		box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    a.link_out{
      border-bottom:1px solid #ccc;
      padding-bottom:1px;
      margin-right:5px;
      &:hover{
        border-bottom-color: #222;
      }
    }
	}

	#close{position:absolute; color:#4C4DD0; cursor:pointer; display:inline-block; padding:15px 20px; top:0; right:0;}
	#close:hover{color:red;}
	p.a{margin-bottom:5vh;}

	.q{font-weight:500;}

	img#port{position:fixed; height:150px; width:auto; bottom:-150px; right:50px; transition:1s ease;}
	img#port.in{bottom:0px;}






	.organized .extra{display:none;}


	.bold{text-transform: uppercase; font-size:11px; font-weight:500; letter-spacing:.05em; display:inline-block;}
	#header{
		height:5vh;
		display:none;
		position:fixed;top:0;left:0;
	}


	#name{opacity:0; transition:.5s ease;}
	.organized #name{opacity:1;}


	.h{  text-transform: uppercase;
	    font-size: 11px;
	    font-weight: 500;
	    letter-spacing: .05em;
}

	#ctn{ z-index:2;}
	.organized #ctn{overflow: auto; height:100%;}



	a{text-decoration:none; cursor:pointer;}
	a, a:visited{color:black;}
	strong{font-weight:500; display:inline-block; }

	img.bg{
		position:absolute; top:50%; left:50%;
		transform:translate(-50%,-50%); z-index:0;
		transition: 1s ease;
		opacity:0;}

	img.bg.in{opacity:1;}



	header{padding:20px;
		display:inline-block;
		position:absolute; top:40%; right:calc(50vw - 250px);
		; z-index:3; transition:1s ease; opacity:0;}
	header.in{opacity:1;}

	header .choices{position:absolute; left: 21%; bottom: -5vh;}

	header a.btn2{display:inline-block; padding:5px 10px; border:1px solid black;
		background:rgba(255,255,255,.5);
		border-radius:2px; cursor:pointer; transition:.1s ease; opacity:1;}
	header a.btn2:hover{background:white;}
	header a.btn2:first-child{margin-right: 3vw;}
	header.moved{top:2vh; right:2vh; position:fixed; transform:translateX(0); }

	.in{opacity:1;}
	.out{opacity:0;}



	li.active{background:white;}


	.notran{transition:0s !important;}



	a.btn{
		background: rgba(0,0,0,.1);
	    padding: 4px 8px;
	    border-radius: 3px;
	    display: inline-block;
	    margin-right:15px;
	}

	a.btn:hover{background:white;}

	#info img, #info p{
		position:absolute;
		left:50%;
		top:50%;
		transform:translate(-50%, -50%);

	}




	.floats{display:block;}
	.floats p{display:inline-block;}


	.mobile{display:none;}
	.notice{color:red; padding:5px;
		background:rgba(0,0,0,.05);
		position:absolute; top:0; left:0; width:100vw;
		padding-left:20px;
	}

	#mobilemore{
		padding:5px 10px;
		background:white;
		border-radius:2px; cursor:pointer; transition:.1s ease;
		margin-top:30px;
		border:0;
		display:inline-block;
	}

	#mobileinfo{display:none; margin-top:40px;     padding-top: 20px;
    border-top: 1px dotted #b3b3b3;}

	.highlightp{border-bottom:1px solid #b3b3b3;
		padding-bottom:2px; display:inline-block;
		position:relative;
	}



		#filter{
		position:fixed;

		bottom:5vh;
		right:0;
		padding:1.5vh 1.5vw;
		border-left:1px solid;
		border-top:1px solid;
		border-bottom:1px solid;
		opacity:0;
		transition:.5s ease;
		box-shadow:0px 3px 15px rgba(0,0,0,0.2);
		border-color:#b3b3b3;

		z-index:3;
	}

	#filter:hover, #about:hover{box-shadow:0px 3px 15px rgba(0,0,0,0.4);}
	#filtertitle{
		cursor:pointer;
		text-align: center;
	}
	#filtertitle:hover{color:blue;}
	#filtertitle span{
		margin:4px 0;
		text-transform:uppercase;
		display:block;

	}
	#filter #filtertitle span.last{
		margin:10px 0 0 0 ;

		display:inline-block;
		font-size:12px;

	}
	#filter.in #filtertitle span.last{margin:0 0 0 10px ;}

	#filter.in #filtertitle span{display:inline; margin: 0 1px 0 0; float:left;}
	#filter.in #filtertitle{overflow:auto; margin-bottom:15px;}

	#filter.in ul{display:block; opacity:1; margin:5px 0; padding:0;}

	#filter ul{list-style: none; display:none; opacity:0; transition: .5s;}
	#filter li{margin:5px 0;}
	.somethingchecked #index .group p.sb{display:none;}
	.somethingchecked #index .group{margin-top:0;}
	#blog-about-mobile{
		display:none;
	}
	footer{
		border-top:1px solid black;
		padding:30px 0;
		display:flex;
		justify-content: space-between;
		align-items: center;
	}
	@media (max-width: 900px){
		#blog-about-mobile{

			display:flex;
			justify-content: space-between;
			align-items: center;
		}
		.blog{

			code{
				// font-size: 12px;
			}
		}
		pre{
			&.highlight{
				padding: 20px 5px;
				-webkit-text-size-adjust: none;
			}
		}
		.projects a{overflow:visible;}
		#filter{display:none;}
		#about{ display:none;}
		img.bg{display:none;}
		.choices{display:none;}
		.mobile{display:block;}

		header strong{text-transform: uppercase; letter-spacing: .1em; margin-right:3px;}
		.highlightp{padding-bottom:0;}
		#index{position:static;
			margin:0;
			max-width:100%;
			padding:20px;
		}
		.projects:first-of-type{margin-top:30px;}

		#index .top{margin-top:0;}
		.projects{margin:40px 0;}
		.projects .tlink{background:white;}
		.dots{display:none;}
		.projects{width:100%;}
		header{position:static; padding-top:30px; display:block !important;}
		.top{
		    border-top: 1px solid black;
		    margin: 0;
		    padding-top: 5vh;
    		font-weight: 500;
		}
		.group{margin-top:1vh;}

    #index{
      .group:first-of-type{
        margin-top:1vh;
      }
    }
		.projects .title{width:auto; display:block; float:none; margin-bottom:1vh;}
		.details{width:auto; padding-left:4px; float:none;     line-height: 20px;}
		.sb{display:none;}
		#about .top{display:none;}
		#blog-about{
			display:none;
		}
		.blog{

			main{
				margin: 20px auto;
				margin-top:0;
				padding: 10px;
				.top{
					margin:0;
				}
			}
		}
	}
